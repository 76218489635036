<template>
  <div>
    <div style="background:#fff;">
      <div class="remind flex flex-center">
        <div>提醒名称</div>
        <input type="text" placeholder="请填写" />
      </div>
      <div class="remind flex flex-center">
        <div>发起人</div>
        <input type="text" placeholder="请填写" />
      </div>
      <div class="remind-textarea">
        <div class="remindText-title">提醒说明</div>
        <textarea placeholder="请填写提醒说明"></textarea>
      </div>
      <div class="remind flex flex-center">
        <div>提醒时间</div>
        <div class="flex flex-center">
          <div>7:00</div>
          <img class="remind-icon" src="../assets/imgs/arrow.png" alt="" />
        </div>
      </div>
      <div class="remind flex flex-center">
        <div>截至时间</div>
        <div class="flex flex-center">
          <div>7:00</div>
          <img class="remind-icon" src="../assets/imgs/arrow.png" alt="" />
        </div>
      </div>
      <div>
        <div class="remind-text">对应内容</div>
        <div class="flex">
          <img
            class="remindContentImg"
            src="../assets/imgs/homeBg.png"
            alt=""
          />
          <div>
            <div class="remindContent-title">活动标题活动标题</div>
            <div class="remindContent-text">
              简介简介简介简介简介简介简介 简介简介简介简介简介简介简介
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="padding-b"></div>
    <!-- 底部固定 -->
    <div class="bottom fixed flex">
      <div class="bottom-add flex-item_center" @click="router('/CreateFollow')">
        添加
      </div>
    </div>

    <VuePicker
      :data="list"
      :showToolbar="true"
      @cancel="pickerVisible = false"
      @confirm="confirm"
      :visible.sync="pickerVisible"
    />
  </div>
</template>

<script>
import VuePicker from "vue-pickers";

export default {
  components: { VuePicker },
  data() {
    return {
      list: [
        [
          { label: "00", value: "男" },
          { label: "01", value: "女" },
          { label: "02", value: "女" },
          { label: "03", value: "女" },
          { label: "04", value: "女" },
          { label: "05", value: "女" },
          { label: "06", value: "女" },
          { label: "07", value: "女" },
          { label: "08", value: "女" },
          { label: "09", value: "女" },
          { label: "10", value: "女" },
          { label: "11", value: "女" },
          { label: "12", value: "女" },
          { label: "13", value: "女" },
          { label: "14", value: "女" },
          { label: "15", value: "女" },
          { label: "16", value: "女" },
          { label: "18", value: "女" },
          { label: "19", value: "女" },
          { label: "20", value: "女" },
          { label: "21", value: "女" },
          { label: "22", value: "女" },
          { label: "23", value: "女" },
        ],
        [
          { label: "00", value: "男" },
          { label: "01", value: "女" },
          { label: "02", value: "女" },
          { label: "03", value: "女" },
          { label: "04", value: "女" },
          { label: "05", value: "女" },
          { label: "06", value: "女" },
          { label: "07", value: "女" },
          { label: "08", value: "女" },
          { label: "09", value: "女" },
          { label: "10", value: "女" },
          { label: "11", value: "女" },
          { label: "12", value: "女" },
          { label: "13", value: "女" },
          { label: "14", value: "女" },
          { label: "15", value: "女" },
          { label: "16", value: "女" },
          { label: "17", value: "女" },
          { label: "18", value: "女" },
          { label: "19", value: "女" },
          { label: "20", value: "女" },
          { label: "21", value: "女" },
          { label: "22", value: "女" },
          { label: "23", value: "女" },
          { label: "24", value: "女" },
          { label: "25", value: "女" },
          { label: "26", value: "女" },
          { label: "27", value: "女" },
          { label: "28", value: "女" },
          { label: "29", value: "女" },
          { label: "30", value: "女" },
          { label: "31", value: "女" },
          { label: "32", value: "女" },
          { label: "33", value: "女" },
          { label: "34", value: "女" },
          { label: "35", value: "女" },
          { label: "36", value: "女" },
          { label: "37", value: "女" },
          { label: "38", value: "女" },
          { label: "39", value: "女" },
          { label: "40", value: "女" },
          { label: "41", value: "女" },
          { label: "42", value: "女" },
          { label: "43", value: "女" },
          { label: "44", value: "女" },
          { label: "45", value: "女" },
          { label: "46", value: "女" },
          { label: "47", value: "女" },
          { label: "48", value: "女" },
          { label: "49", value: "女" },
          { label: "50", value: "女" },
          { label: "51", value: "女" },
          { label: "52", value: "女" },
          { label: "53", value: "女" },
          { label: "54", value: "女" },
          { label: "55", value: "女" },
          { label: "56", value: "女" },
          { label: "57", value: "女" },
          { label: "58", value: "女" },
          { label: "59", value: "女" },
        ],
      ],
      pickerVisible: true,
    };
  },
  methods: {
    confirm(res) {
      this.$set(this.postData[this.index], "sex", res[0].value);
      console.log("res", res);
    },
  },
};
</script>

<style scoped>
.remind {
  margin: 0 auto;
  width: 670px;
  background: #fff;
  border-bottom: 1px solid #ededf7;
  padding: 64px 0 26px 0;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  justify-content: space-between;
  color: #66728f;
}

.remind input {
  outline: none;
  border: 0;
  text-align: right;
}

.remind-textarea {
  margin: 0 auto;
  width: 670px;
  background: #fff;
  border-bottom: 1px solid #ededf7;
  padding: 64px 0 26px 0;
}

.remindText-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
}

textarea {
  outline: none;
  border: 0;
  width: 670px;
  margin-top: 38px;
  height: 110px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
}

.remind-icon {
  width: 12px;
  height: 20px;
  margin-left: 10px;
}

.remind-text {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
  padding: 52px 0 52px 40px;
}

.remindContentImg {
  width: 185px;
  height: 185px;
  border-radius: 16px;
  padding: 0 20px 40px 40px;
}

.remindContent-title {
  width: 336px;
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  white-space: nowrap;
}

.remindContent-text {
  margin-top: 14px;
  width: 336px;
  height: 66px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d8393;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 可以显示的行数，超出部分用...表示*/
  -webkit-box-orient: vertical;
}

.bottom {
  width: 100%;
  bottom: 0;
  left: 0;
  height: 151px;
  background: #ffffff;
  justify-content: center;
  border-top: 1px solid #c3c3c3;
}

.bottom-add {
  width: 670px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 15px;
}

.taskC-topImg {
  width: 28px;
  height: 29px;
}

.padding-b {
  height: 200px;
}
</style>
